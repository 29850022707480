<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar :title="blockName+'排行'" left-arrow @click-left="$router.back()">
        <template #right>
          <div @click="toeshop()">
            <div style="float: right; margin-left: 5px; font-size: 15px">
              {{blockName}}商城
            </div>
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>

    <van-tabs v-model="activeName" sticky>
      <van-tab title="总排行" name="z">
        <div class="tabcontent">
          <Ranklist filterData="0" :blockId="blockId"></Ranklist>
        </div>
      </van-tab>
      <van-tab title="7日内排行" name="a">
        <div class="tabcontent">
          <Ranklist filterData="1" :blockId="blockId"></Ranklist>
        </div>
      </van-tab>

      <van-tab title="30日内排行" name="b">
        <div class="tabcontent">
          <Ranklist filterData="2" :blockId="blockId"></Ranklist>
        </div>
      </van-tab>

      <van-tab title="3月内排行" name="c">
        <div class="tabcontent">
          <Ranklist filterData="3" :blockId="blockId"></Ranklist>
        </div>
      </van-tab>
      <van-tab title="1年内排行" name="d">
        <div class="tabcontent">
          <Ranklist filterData="4" :blockId="blockId"></Ranklist>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Ranklist from "./components/ranklist";

//const key = "zn-history";
export default {
  name: "scorerank",
  data() {
    return {
      activeName: "z",
      title: "积分排行",
      blockId: 0,
      blockName: "",
    };
  },
  components: {
    Ranklist,
  },
  activated() {
    // this.blockId = this.$route.query.blockId;
    // if (this.blockId == 0) {
    //   this.blockName = "积分";
    // } else if (this.blockId == 1) {
    //   this.blockName = "能量值";
    // }
  },
  created() {
    this.blockId = this.$route.query.blockId;

    if (this.blockId == 0) {
      this.blockName = "积分";
    } else if (this.blockId == 1) {
      this.blockName = "能量值";
    }
  },
  mounted() {},

  methods: {
    toeshop() {
      this.$router.push({ path: "/eshop", query: { blockId: this.blockId }});
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 80px;
  }
}
.zntitle {
  height: 80px;
}

.tabcontent {
  height: 100%;
  //height:200px;
  //position:relative;
  //  height: inherit;
  // //overflow: hidden;
  // //overflow-y: auto;
  // box-sizing: border-box;
  // //position: absolute;
  // position
}
.container {
  height: 100%;
  // height: inherit;
  // //overflow: hidden;
  // //overflow-y: auto;
  // box-sizing: border-box;
  //position: absolute;
  /deep/ .van-tabs {
    height: 100%;
  }
  /deep/.van-tabs__content {
    height: 100%;
  }
}
.van-tabs__content {
  height: 100%;
}

.van-tab__pane {
  height: 100%;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
