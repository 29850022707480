<template>
  <div class="containerlist" ref="wrapper">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <div class="van-clearfix">
          <div
            style="margin-top: 5px; margin-bottom: 5px"
            v-for="(item, index) in articles"
            :key="item.userId"
          >
            <van-row>
              <van-col span="4">
                <div
                  style="    margin-left: 10px;
    text-align: center;
    font-size: 15px;  height: 41px;margin-top: 8px; color: #6c6c6c;"
                >
                  {{ index + 1 }}
                </div>
              </van-col>
              <van-col span="3">
                <van-image
                  round
                  width="41px"
                  height="41px"
                  fit="fill"
                  :src="item.headImgurl"
                  @click.stop="toUserHomePage(item.userId)"
                />
              </van-col>
              <van-col span="11">
                <div
                  style="
                   
                    color: #069;
                    font-size: 16px;
                    font-weight: bold;
                    width: 100%;
                    height: 41px;
                  "
                >
                  <div style="margin-top: 8px">
                    {{ item.trueName }} ( {{ item.department }} )

                    <userIcon :iconstring="item.icon"></userIcon>
                  </div>
                </div>
              </van-col>
              <van-col span="6">
                <div
                  style="  
    font-size: 18px;  height: 41px;margin-top: 8px;color:rgb(254, 164, 18)"
                >
                  {{ item.score }}
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getRankList } from "@/api/score";

import UserIcon from "@/views/components/userIcon";

//const key = "zn-history";
export default {
  name: "ranklist",
  props: {
    filterData: String,
    blockId:String,
  },
  data() {
    return {
      scroll: 0,
      refreshing: false,

      title: "",

      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 20,
        sorterType: 0,
        filterData: 0,
      },
    };
  },
  components: { UserIcon },
  activated() {
    window.scrollTo(0, this.scroll);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
    this.onSearch();
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.filterData = parseInt(this.filterData);
    },

    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    // toUserHomePage(id) {
    //     this.$router.push({
    //       path: "/userHomepage",
    //       query: { para: id },
    //     });
    // },
    toUserHomePage(id) {
      try {
        let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
        this.scroll = wrapperScrollTop;
        this.$router.push({
          path: "/userHomepage",
          query: { para: id },
        });
      } catch (error) {
        console.log(error);
      }
    },

    async onLoad() {
      this.upLoading = true;
      // if (this.articles.length > 0 && this.page.current == 1) {
      //    this.upLoading = false;
      //   return;
      // }
      if(!this.blockId){
        return;
      }

      let aresult = await getRankList({ ...this.page },this.blockId); //({ ...this.page })
      console.log(aresult);
      this.articles.push(...aresult.data.data);
      this.upLoading = false;

      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.containerlist {
  //height:  400px;
  height: 100%;
  //overflow-y: visible;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}
</style>
